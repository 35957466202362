.payment-form {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  .card-element-container {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .payment-button {
    width: 100%;
    padding: 12px;
    background-color: #5469d4;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .payment-button:disabled {
    background-color: #939393;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #dc3545;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .success-message {
    color: #28a745;
    margin-bottom: 10px;
    font-size: 14px;
  }
  /* Payment method selector styles */
.payment-container {
  width: 100%;
  max-width: 500px;
}

.payment-method-selector {
  display: flex;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
}

.method-button {
  flex: 1;
  padding: 12px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.method-button.active {
  background-color: #6772e5;
  color: white;
}

.payment-form {
  width: 100%;
}

.xmoney-info {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
}

/* Existing styles for the payment button and message containers remain the same */